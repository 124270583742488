var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "misc-wrapper" },
    [
      _c("b-link", { staticClass: "brand-logo mt-0 mb-0" }, [
        _c("img", { attrs: { src: _vm.style.loginImage, width: "150px" } }),
      ]),
      _c("div", { staticClass: "misc-inner p-2 p-sm-3" }, [
        _c(
          "div",
          { staticClass: "w-100 text-center" },
          [
            _c("h3", { staticClass: "mb-1" }, [
              _vm._v(
                " Ocorreu um erro inesperado ao carregar as informações do PDV ❌ "
              ),
            ]),
            _c("p", { staticClass: "mb-3" }, [
              _vm._v(
                " Por favor tente novamente atualizando a página e se o erro persistir, entre em contato com o suporte. "
              ),
            ]),
            _c(
              "b-button",
              {
                staticClass: "mb-1 btn-sm-block",
                attrs: { variant: "primary" },
                on: { click: _vm.refresh },
              },
              [_vm._v("Atualizar página")]
            ),
            _c("b-img", { attrs: { fluid: "", src: _vm.imgUrl } }),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }